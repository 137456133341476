<template>
  <ion-page>
    <ion-content :fullscreen="true">
        <ion-grid style="margin-top: 40%">
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label position="stacked">Логін</ion-label>
                        <ion-input @IonChange="userName=$event.target.value"></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label position="stacked">Пароль</ion-label>
                        <ion-input type="password" @IonChange="userPassword=$event.target.value"></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-button color="primary" style="width: 100%" @click="login">Увійти</ion-button>
                </ion-col>
            </ion-row>
            <ion-row style="text-align: center">
                <ion-col>
                    <ion-text color="dark">
                        <h2 style="line-height: 1.5">Інформаційне Агентство<br>
                            УКРАЇНА ІНФОРМ</h2>
                    </ion-text>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col style="text-align: center;padding: 10px;">
                    <img src="/assets/logo.jpeg">
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonCol, IonGrid, IonRow, IonContent, IonText, IonInput} from '@ionic/vue';
import { appName } from "@/constant/text";
import {AndroidPermissions} from "@ionic-native/android-permissions";
import {Capacitor} from "@capacitor/core";

export default  {
    name: 'Login',
    components: { IonCol, IonGrid, IonRow, IonContent, IonPage, IonText, IonInput },
    data() {
        return {
            appName: appName,
            userName: 'admin@example.com',
            userPassword: '1111111',
            isOpenLoad: false,
            androidPermissions: [
                AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                AndroidPermissions.PERMISSION.CAMERA,
                AndroidPermissions.PERMISSION.INTERNET,
                AndroidPermissions.PERMISSION.RECORD_AUDIO,
                AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
                AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
                AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
            ]
        }
    },
    methods: {
        login() {
            //alert('x988244');
            const self = this;
            self.isOpenLoad = true;
            console.log(this.userName);
            this.$store.dispatch('user/login', {userName: this.userName, userPassword: this.userPassword})
                .then(() => {
                    console.log(self.$router);
                    self.$router.push('/select');
                }).catch(err => {
                    console.log(err);
                    self.isOpenLoad = false;
                })
        },
        async checkPerMision() {
            this.androidPermissions.forEach(function (item) {
                if(Capacitor.getPlatform() === 'android') {
                    AndroidPermissions.checkPermission(item).then(
                        result => { console.log(item + ' ' + result.hasPermission); },
                        err => { alert(err); }
                    );
                }
            });
        }
    }
}
</script>
